import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import { SignUpPage } from "../components/auth";
const Signup = () => (
  <Layout>
    <SEO title="Sign Up" />
    <SignUpPage />
  </Layout>
);

export default Signup;
